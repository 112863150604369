import Public from "templates/Public"

import DynamicLink from "components/interactive/DynamicLink"
import Error from "components/presentational/Error"
import HTMLTitle from "components/utility/HTMLTitle"

const FourOhFour = () => (
  <>
    <HTMLTitle>Something went wrong</HTMLTitle>

    <Error
      type="404"
      heading="Sorry, we couldn't find the page you were looking for."
      content={
        <>
          <span>
            Try going back to the{" "}
            <DynamicLink href="/" className="inline-link">
              home page.
            </DynamicLink>
          </span>
        </>
      }
    />
  </>
)

FourOhFour.template = Public

export default FourOhFour
