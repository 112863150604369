import Footer from "components/shell/Footer"

import type { Children } from "types"

const Public = ({ children }: Children) => {
  return (
    <>
      <div className="public-template footer-only">{children}</div>
      <Footer />
    </>
  )
}

export default Public
