import Image from "next/image"
import { Box, Typography } from "@mui/material"

const imageMap = {
  "404": {
    src: "/ui/error/404.png",
    alt: "A line drawing of a person with 4's on either side of their head, with the head being a 0; 404",
  },
  generic: {
    src: "/ui/error/generic.png",
    alt: "A line drawing of a person standing in front of a tangled mess of wires; generic",
  },
  "missing-data": {
    src: "/ui/error/missing-data.png",
    alt: "A line drawing of a person pointing at a vertical array of cubes with the second cube missing; missing data",
  },
}

const center = {
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
  flexDirection: "column",
}

interface Props {
  type: "404" | "generic" | "missing-data"
  heading: React.ReactNode
  content: React.ReactNode
}

const Error = ({ type, heading, content }: Props) => {
  const getImageDetails = () => {
    if (imageMap[type]) {
      return imageMap[type]
    }

    return imageMap.generic
  }

  const image = getImageDetails()

  return (
    <Box
      sx={{
        width: "100%",
        height: "100%",
        padding: "11% 0",
        ...center,
      }}
    >
      <Box
        sx={{
          ...center,
        }}
      >
        <Image src={image.src} alt={image.alt} width={200} height={200} />

        <Box
          sx={{
            ...center,
            textAlign: "center",
            maxWidth: "28rem",
            mt: 2,
          }}
        >
          <Typography
            sx={{
              fontSize: "1.125rem",
              fontWeight: 600,
              mb: 1,
            }}
          >
            {heading}
          </Typography>

          {content}
        </Box>
      </Box>
    </Box>
  )
}

export default Error
